import React from 'react';
import './threeSection.css';

const ThreeSections = () => (
  <div className="three-sections-container">
    <div className="first-section">
      <div className="phone-image">
        <img src="https://user-images.githubusercontent.com/36542986/280459168-1c228580-1420-4179-8c52-a3eebc300b9f.png" alt="phone" />
      </div>
      <div className="text">
        <div className="d-flex">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" fill="none">
            <path d="M17.5504 2.14502H4.96797C4.22665 2.14583 3.51592 2.44068 2.99173 2.96487C2.46753 3.48907 2.17268 4.19979 2.17188 4.94111V17.5235C2.17268 18.2649 2.46753 18.9756 2.99173 19.4998C3.51592 20.024 4.22665 20.3188 4.96797 20.3196H17.5504C18.2917 20.3188 19.0024 20.024 19.5266 19.4998C20.0508 18.9756 20.3457 18.2649 20.3465 17.5235V4.94111C20.3457 4.19979 20.0508 3.48907 19.5266 2.96487C19.0024 2.44068 18.2917 2.14583 17.5504 2.14502ZM15.9885 8.18677L10.1167 15.177C10.0523 15.2537 9.97216 15.3157 9.88171 15.3587C9.79126 15.4018 9.69261 15.4249 9.59245 15.4265H9.58065C9.48268 15.4264 9.3858 15.4058 9.29631 15.3659C9.20682 15.326 9.12671 15.2678 9.06119 15.1949L6.54471 12.3988C6.4808 12.331 6.43108 12.2512 6.39848 12.1639C6.36588 12.0766 6.35106 11.9837 6.35488 11.8906C6.3587 11.7975 6.38109 11.7062 6.42073 11.6219C6.46037 11.5375 6.51647 11.462 6.58572 11.3997C6.65497 11.3374 6.73598 11.2895 6.82398 11.259C6.91199 11.2284 7.00522 11.2157 7.09819 11.2217C7.19116 11.2277 7.28199 11.2522 7.36536 11.2938C7.44872 11.3354 7.52293 11.3932 7.58363 11.4639L9.5623 13.6623L14.9181 7.28765C15.0383 7.14875 15.2082 7.06271 15.3913 7.04813C15.5744 7.03354 15.7558 7.09158 15.8964 7.20969C16.0371 7.32781 16.1255 7.49652 16.1428 7.67936C16.16 7.86219 16.1046 8.04446 15.9885 8.18677Z" fill="#622B7E" />
          </svg>
          <div className="trade-ease">
            Trade with ease
          </div>
        </div>
        <div className="multi-curr-accept">
          <div>
            Multi-Currency Support
          </div>
          <div>
            Accept a wide array of digital currencies,
            including Bitcoin, Ethereum, and more.
            Expand your customer base by accommodating
            diverse payment preferences.
          </div>
        </div>
        <div className="multi-curr-accept multi-curr-second">
          <div>
            Multi-Currency Support
          </div>
          <div>
            Accept a wide array of digital currencies,
            including Bitcoin, Ethereum, and more.
            Expand your customer base by accommodating
            diverse payment preferences.
          </div>
        </div>
        <div className="d-flex arrow-get-started">
          <div className="arrow">
            <img src="https://user-images.githubusercontent.com/36542986/280401623-f68eb932-b321-4eb1-9df4-ff6a80f0bf04.png" alt="arrow" />
          </div>
          <div className="get-started">
            Here’s how to get started
          </div>
        </div>
      </div>
    </div>
    <div className="second-section">
      <div className="image">
        <img src="https://user-images.githubusercontent.com/36542986/280459276-9597a639-4ef5-4d61-b363-5315f17838e5.jpg" alt="handphone" />
      </div>
      <div className="text">
        <div className="d-flex">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" fill="none">
            <path d="M17.5504 2.14502H4.96797C4.22665 2.14583 3.51592 2.44068 2.99173 2.96487C2.46753 3.48907 2.17268 4.19979 2.17188 4.94111V17.5235C2.17268 18.2649 2.46753 18.9756 2.99173 19.4998C3.51592 20.024 4.22665 20.3188 4.96797 20.3196H17.5504C18.2917 20.3188 19.0024 20.024 19.5266 19.4998C20.0508 18.9756 20.3457 18.2649 20.3465 17.5235V4.94111C20.3457 4.19979 20.0508 3.48907 19.5266 2.96487C19.0024 2.44068 18.2917 2.14583 17.5504 2.14502ZM15.9885 8.18677L10.1167 15.177C10.0523 15.2537 9.97216 15.3157 9.88171 15.3587C9.79126 15.4018 9.69261 15.4249 9.59245 15.4265H9.58065C9.48268 15.4264 9.3858 15.4058 9.29631 15.3659C9.20682 15.326 9.12671 15.2678 9.06119 15.1949L6.54471 12.3988C6.4808 12.331 6.43108 12.2512 6.39848 12.1639C6.36588 12.0766 6.35106 11.9837 6.35488 11.8906C6.3587 11.7975 6.38109 11.7062 6.42073 11.6219C6.46037 11.5375 6.51647 11.462 6.58572 11.3997C6.65497 11.3374 6.73598 11.2895 6.82398 11.259C6.91199 11.2284 7.00522 11.2157 7.09819 11.2217C7.19116 11.2277 7.28199 11.2522 7.36536 11.2938C7.44872 11.3354 7.52293 11.3932 7.58363 11.4639L9.5623 13.6623L14.9181 7.28765C15.0383 7.14875 15.2082 7.06271 15.3913 7.04813C15.5744 7.03354 15.7558 7.09158 15.8964 7.20969C16.0371 7.32781 16.1255 7.49652 16.1428 7.67936C16.16 7.86219 16.1046 8.04446 15.9885 8.18677Z" fill="#622B7E" />
          </svg>
          <div className="trade-ease">
            Trade with ease
          </div>
        </div>
        <div className="multi-curr-accept">
          <div>
            Multi-Currency Support
          </div>
        </div>
        <div className="mini-trade">
          <div className="d-flex">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none">
              <rect width="15.6581" height="15.6581" transform="translate(0.878906 0.151367)" fill="white" />
              <path d="M13.1116 1.61865H4.30395C3.78502 1.61922 3.28751 1.82561 2.92058 2.19255C2.55364 2.55948 2.34725 3.05699 2.34668 3.57592V12.3836C2.34725 12.9025 2.55364 13.4001 2.92058 13.767C3.28751 14.1339 3.78502 14.3403 4.30395 14.3409H13.1116C13.6306 14.3403 14.1281 14.1339 14.495 13.767C14.862 13.4001 15.0683 12.9025 15.0689 12.3836V3.57592C15.0683 3.05699 14.862 2.55948 14.495 2.19255C14.1281 1.82561 13.6306 1.61922 13.1116 1.61865ZM12.0183 5.84788L7.90807 10.741C7.86298 10.7947 7.80688 10.8381 7.74356 10.8683C7.68025 10.8984 7.61119 10.9146 7.54108 10.9157H7.53283C7.46424 10.9156 7.39643 10.9012 7.33378 10.8733C7.27114 10.8454 7.21507 10.8046 7.1692 10.7536L5.40766 8.79632C5.36292 8.74887 5.32812 8.69295 5.30531 8.63186C5.28249 8.57077 5.27211 8.50574 5.27478 8.44058C5.27746 8.37542 5.29313 8.31145 5.32088 8.25244C5.34863 8.19342 5.38789 8.14055 5.43637 8.09692C5.48484 8.0533 5.54155 8.01981 5.60316 7.99841C5.66476 7.97702 5.73002 7.96815 5.7951 7.97234C5.86018 7.97652 5.92376 7.99368 5.98212 8.02279C6.04047 8.0519 6.09242 8.09238 6.13491 8.14186L7.51998 9.68076L11.2691 5.21849C11.3532 5.12127 11.4721 5.06104 11.6003 5.05083C11.7284 5.04062 11.8554 5.08124 11.9539 5.16393C12.0523 5.24661 12.1142 5.36471 12.1263 5.49269C12.1384 5.62067 12.0996 5.74826 12.0183 5.84788Z" fill="#916BA5" />
            </svg>
            <div className="mini-trade-text">
              Trade with ease
            </div>
          </div>
          <div className="d-flex">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none">
              <rect width="15.6581" height="15.6581" transform="translate(0.878906 0.151367)" fill="white" />
              <path d="M13.1116 1.61865H4.30395C3.78502 1.61922 3.28751 1.82561 2.92058 2.19255C2.55364 2.55948 2.34725 3.05699 2.34668 3.57592V12.3836C2.34725 12.9025 2.55364 13.4001 2.92058 13.767C3.28751 14.1339 3.78502 14.3403 4.30395 14.3409H13.1116C13.6306 14.3403 14.1281 14.1339 14.495 13.767C14.862 13.4001 15.0683 12.9025 15.0689 12.3836V3.57592C15.0683 3.05699 14.862 2.55948 14.495 2.19255C14.1281 1.82561 13.6306 1.61922 13.1116 1.61865ZM12.0183 5.84788L7.90807 10.741C7.86298 10.7947 7.80688 10.8381 7.74356 10.8683C7.68025 10.8984 7.61119 10.9146 7.54108 10.9157H7.53283C7.46424 10.9156 7.39643 10.9012 7.33378 10.8733C7.27114 10.8454 7.21507 10.8046 7.1692 10.7536L5.40766 8.79632C5.36292 8.74887 5.32812 8.69295 5.30531 8.63186C5.28249 8.57077 5.27211 8.50574 5.27478 8.44058C5.27746 8.37542 5.29313 8.31145 5.32088 8.25244C5.34863 8.19342 5.38789 8.14055 5.43637 8.09692C5.48484 8.0533 5.54155 8.01981 5.60316 7.99841C5.66476 7.97702 5.73002 7.96815 5.7951 7.97234C5.86018 7.97652 5.92376 7.99368 5.98212 8.02279C6.04047 8.0519 6.09242 8.09238 6.13491 8.14186L7.51998 9.68076L11.2691 5.21849C11.3532 5.12127 11.4721 5.06104 11.6003 5.05083C11.7284 5.04062 11.8554 5.08124 11.9539 5.16393C12.0523 5.24661 12.1142 5.36471 12.1263 5.49269C12.1384 5.62067 12.0996 5.74826 12.0183 5.84788Z" fill="#916BA5" />
            </svg>
            <div className="mini-trade-text">
              Fast Payouts
            </div>
          </div>
          <div className="d-flex">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none">
              <rect width="15.6581" height="15.6581" transform="translate(0.878906 0.151367)" fill="white" />
              <path d="M13.1116 1.61865H4.30395C3.78502 1.61922 3.28751 1.82561 2.92058 2.19255C2.55364 2.55948 2.34725 3.05699 2.34668 3.57592V12.3836C2.34725 12.9025 2.55364 13.4001 2.92058 13.767C3.28751 14.1339 3.78502 14.3403 4.30395 14.3409H13.1116C13.6306 14.3403 14.1281 14.1339 14.495 13.767C14.862 13.4001 15.0683 12.9025 15.0689 12.3836V3.57592C15.0683 3.05699 14.862 2.55948 14.495 2.19255C14.1281 1.82561 13.6306 1.61922 13.1116 1.61865ZM12.0183 5.84788L7.90807 10.741C7.86298 10.7947 7.80688 10.8381 7.74356 10.8683C7.68025 10.8984 7.61119 10.9146 7.54108 10.9157H7.53283C7.46424 10.9156 7.39643 10.9012 7.33378 10.8733C7.27114 10.8454 7.21507 10.8046 7.1692 10.7536L5.40766 8.79632C5.36292 8.74887 5.32812 8.69295 5.30531 8.63186C5.28249 8.57077 5.27211 8.50574 5.27478 8.44058C5.27746 8.37542 5.29313 8.31145 5.32088 8.25244C5.34863 8.19342 5.38789 8.14055 5.43637 8.09692C5.48484 8.0533 5.54155 8.01981 5.60316 7.99841C5.66476 7.97702 5.73002 7.96815 5.7951 7.97234C5.86018 7.97652 5.92376 7.99368 5.98212 8.02279C6.04047 8.0519 6.09242 8.09238 6.13491 8.14186L7.51998 9.68076L11.2691 5.21849C11.3532 5.12127 11.4721 5.06104 11.6003 5.05083C11.7284 5.04062 11.8554 5.08124 11.9539 5.16393C12.0523 5.24661 12.1142 5.36471 12.1263 5.49269C12.1384 5.62067 12.0996 5.74826 12.0183 5.84788Z" fill="#916BA5" />
            </svg>
            <div className="mini-trade-text">
              Global Payouts
            </div>
          </div>
          <div className="d-flex">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none">
              <rect width="15.6581" height="15.6581" transform="translate(0.878906 0.151367)" fill="white" />
              <path d="M13.1116 1.61865H4.30395C3.78502 1.61922 3.28751 1.82561 2.92058 2.19255C2.55364 2.55948 2.34725 3.05699 2.34668 3.57592V12.3836C2.34725 12.9025 2.55364 13.4001 2.92058 13.767C3.28751 14.1339 3.78502 14.3403 4.30395 14.3409H13.1116C13.6306 14.3403 14.1281 14.1339 14.495 13.767C14.862 13.4001 15.0683 12.9025 15.0689 12.3836V3.57592C15.0683 3.05699 14.862 2.55948 14.495 2.19255C14.1281 1.82561 13.6306 1.61922 13.1116 1.61865ZM12.0183 5.84788L7.90807 10.741C7.86298 10.7947 7.80688 10.8381 7.74356 10.8683C7.68025 10.8984 7.61119 10.9146 7.54108 10.9157H7.53283C7.46424 10.9156 7.39643 10.9012 7.33378 10.8733C7.27114 10.8454 7.21507 10.8046 7.1692 10.7536L5.40766 8.79632C5.36292 8.74887 5.32812 8.69295 5.30531 8.63186C5.28249 8.57077 5.27211 8.50574 5.27478 8.44058C5.27746 8.37542 5.29313 8.31145 5.32088 8.25244C5.34863 8.19342 5.38789 8.14055 5.43637 8.09692C5.48484 8.0533 5.54155 8.01981 5.60316 7.99841C5.66476 7.97702 5.73002 7.96815 5.7951 7.97234C5.86018 7.97652 5.92376 7.99368 5.98212 8.02279C6.04047 8.0519 6.09242 8.09238 6.13491 8.14186L7.51998 9.68076L11.2691 5.21849C11.3532 5.12127 11.4721 5.06104 11.6003 5.05083C11.7284 5.04062 11.8554 5.08124 11.9539 5.16393C12.0523 5.24661 12.1142 5.36471 12.1263 5.49269C12.1384 5.62067 12.0996 5.74826 12.0183 5.84788Z" fill="#916BA5" />
            </svg>
            <div className="mini-trade-text">
              Fast Settlements
            </div>
          </div>
        </div>
        <div className="accept-text">
          <ul>
            <li>
              Accept a wide array of digital currencies,
              including Bitcoin, Ethereum, and more.
            </li>
            <li>
              Expand your customer base by accommodating
              diverse payment preferences.
            </li>
          </ul>
        </div>
        <div className="d-flex arrow-get-started">
          <div className="arrow">
            <img src="https://user-images.githubusercontent.com/36542986/280401623-f68eb932-b321-4eb1-9df4-ff6a80f0bf04.png" alt="arrow" />
          </div>
          <div className="get-started">
            Here’s how to get started
          </div>
        </div>
      </div>
    </div>
    <div className="third-section">
      <div className="image">
        <img src="https://user-images.githubusercontent.com/36542986/280462465-23a1f2db-d6d0-4594-9797-477d81d709f3.jpg" alt="dashboard" />
      </div>
      <div className="text">
        <div className="d-flex">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" fill="none">
            <path d="M17.5504 2.14502H4.96797C4.22665 2.14583 3.51592 2.44068 2.99173 2.96487C2.46753 3.48907 2.17268 4.19979 2.17188 4.94111V17.5235C2.17268 18.2649 2.46753 18.9756 2.99173 19.4998C3.51592 20.024 4.22665 20.3188 4.96797 20.3196H17.5504C18.2917 20.3188 19.0024 20.024 19.5266 19.4998C20.0508 18.9756 20.3457 18.2649 20.3465 17.5235V4.94111C20.3457 4.19979 20.0508 3.48907 19.5266 2.96487C19.0024 2.44068 18.2917 2.14583 17.5504 2.14502ZM15.9885 8.18677L10.1167 15.177C10.0523 15.2537 9.97216 15.3157 9.88171 15.3587C9.79126 15.4018 9.69261 15.4249 9.59245 15.4265H9.58065C9.48268 15.4264 9.3858 15.4058 9.29631 15.3659C9.20682 15.326 9.12671 15.2678 9.06119 15.1949L6.54471 12.3988C6.4808 12.331 6.43108 12.2512 6.39848 12.1639C6.36588 12.0766 6.35106 11.9837 6.35488 11.8906C6.3587 11.7975 6.38109 11.7062 6.42073 11.6219C6.46037 11.5375 6.51647 11.462 6.58572 11.3997C6.65497 11.3374 6.73598 11.2895 6.82398 11.259C6.91199 11.2284 7.00522 11.2157 7.09819 11.2217C7.19116 11.2277 7.28199 11.2522 7.36536 11.2938C7.44872 11.3354 7.52293 11.3932 7.58363 11.4639L9.5623 13.6623L14.9181 7.28765C15.0383 7.14875 15.2082 7.06271 15.3913 7.04813C15.5744 7.03354 15.7558 7.09158 15.8964 7.20969C16.0371 7.32781 16.1255 7.49652 16.1428 7.67936C16.16 7.86219 16.1046 8.04446 15.9885 8.18677Z" fill="#622B7E" />
          </svg>
          <div className="trade-ease">
            Trade with ease
          </div>
        </div>
        <div className="multi-curr-accept">
          <div>
            Multi-Currency Support
          </div>
        </div>
        <div className="mini-trade">
          <div className="d-flex">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none">
              <rect width="15.6581" height="15.6581" transform="translate(0.878906 0.151367)" fill="white" />
              <path d="M13.1116 1.61865H4.30395C3.78502 1.61922 3.28751 1.82561 2.92058 2.19255C2.55364 2.55948 2.34725 3.05699 2.34668 3.57592V12.3836C2.34725 12.9025 2.55364 13.4001 2.92058 13.767C3.28751 14.1339 3.78502 14.3403 4.30395 14.3409H13.1116C13.6306 14.3403 14.1281 14.1339 14.495 13.767C14.862 13.4001 15.0683 12.9025 15.0689 12.3836V3.57592C15.0683 3.05699 14.862 2.55948 14.495 2.19255C14.1281 1.82561 13.6306 1.61922 13.1116 1.61865ZM12.0183 5.84788L7.90807 10.741C7.86298 10.7947 7.80688 10.8381 7.74356 10.8683C7.68025 10.8984 7.61119 10.9146 7.54108 10.9157H7.53283C7.46424 10.9156 7.39643 10.9012 7.33378 10.8733C7.27114 10.8454 7.21507 10.8046 7.1692 10.7536L5.40766 8.79632C5.36292 8.74887 5.32812 8.69295 5.30531 8.63186C5.28249 8.57077 5.27211 8.50574 5.27478 8.44058C5.27746 8.37542 5.29313 8.31145 5.32088 8.25244C5.34863 8.19342 5.38789 8.14055 5.43637 8.09692C5.48484 8.0533 5.54155 8.01981 5.60316 7.99841C5.66476 7.97702 5.73002 7.96815 5.7951 7.97234C5.86018 7.97652 5.92376 7.99368 5.98212 8.02279C6.04047 8.0519 6.09242 8.09238 6.13491 8.14186L7.51998 9.68076L11.2691 5.21849C11.3532 5.12127 11.4721 5.06104 11.6003 5.05083C11.7284 5.04062 11.8554 5.08124 11.9539 5.16393C12.0523 5.24661 12.1142 5.36471 12.1263 5.49269C12.1384 5.62067 12.0996 5.74826 12.0183 5.84788Z" fill="#916BA5" />
            </svg>
            <div className="mini-trade-text">
              Trade with ease
            </div>
          </div>
          <div className="d-flex">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none">
              <rect width="15.6581" height="15.6581" transform="translate(0.878906 0.151367)" fill="white" />
              <path d="M13.1116 1.61865H4.30395C3.78502 1.61922 3.28751 1.82561 2.92058 2.19255C2.55364 2.55948 2.34725 3.05699 2.34668 3.57592V12.3836C2.34725 12.9025 2.55364 13.4001 2.92058 13.767C3.28751 14.1339 3.78502 14.3403 4.30395 14.3409H13.1116C13.6306 14.3403 14.1281 14.1339 14.495 13.767C14.862 13.4001 15.0683 12.9025 15.0689 12.3836V3.57592C15.0683 3.05699 14.862 2.55948 14.495 2.19255C14.1281 1.82561 13.6306 1.61922 13.1116 1.61865ZM12.0183 5.84788L7.90807 10.741C7.86298 10.7947 7.80688 10.8381 7.74356 10.8683C7.68025 10.8984 7.61119 10.9146 7.54108 10.9157H7.53283C7.46424 10.9156 7.39643 10.9012 7.33378 10.8733C7.27114 10.8454 7.21507 10.8046 7.1692 10.7536L5.40766 8.79632C5.36292 8.74887 5.32812 8.69295 5.30531 8.63186C5.28249 8.57077 5.27211 8.50574 5.27478 8.44058C5.27746 8.37542 5.29313 8.31145 5.32088 8.25244C5.34863 8.19342 5.38789 8.14055 5.43637 8.09692C5.48484 8.0533 5.54155 8.01981 5.60316 7.99841C5.66476 7.97702 5.73002 7.96815 5.7951 7.97234C5.86018 7.97652 5.92376 7.99368 5.98212 8.02279C6.04047 8.0519 6.09242 8.09238 6.13491 8.14186L7.51998 9.68076L11.2691 5.21849C11.3532 5.12127 11.4721 5.06104 11.6003 5.05083C11.7284 5.04062 11.8554 5.08124 11.9539 5.16393C12.0523 5.24661 12.1142 5.36471 12.1263 5.49269C12.1384 5.62067 12.0996 5.74826 12.0183 5.84788Z" fill="#916BA5" />
            </svg>
            <div className="mini-trade-text">
              Fast Payouts
            </div>
          </div>
          <div className="d-flex">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none">
              <rect width="15.6581" height="15.6581" transform="translate(0.878906 0.151367)" fill="white" />
              <path d="M13.1116 1.61865H4.30395C3.78502 1.61922 3.28751 1.82561 2.92058 2.19255C2.55364 2.55948 2.34725 3.05699 2.34668 3.57592V12.3836C2.34725 12.9025 2.55364 13.4001 2.92058 13.767C3.28751 14.1339 3.78502 14.3403 4.30395 14.3409H13.1116C13.6306 14.3403 14.1281 14.1339 14.495 13.767C14.862 13.4001 15.0683 12.9025 15.0689 12.3836V3.57592C15.0683 3.05699 14.862 2.55948 14.495 2.19255C14.1281 1.82561 13.6306 1.61922 13.1116 1.61865ZM12.0183 5.84788L7.90807 10.741C7.86298 10.7947 7.80688 10.8381 7.74356 10.8683C7.68025 10.8984 7.61119 10.9146 7.54108 10.9157H7.53283C7.46424 10.9156 7.39643 10.9012 7.33378 10.8733C7.27114 10.8454 7.21507 10.8046 7.1692 10.7536L5.40766 8.79632C5.36292 8.74887 5.32812 8.69295 5.30531 8.63186C5.28249 8.57077 5.27211 8.50574 5.27478 8.44058C5.27746 8.37542 5.29313 8.31145 5.32088 8.25244C5.34863 8.19342 5.38789 8.14055 5.43637 8.09692C5.48484 8.0533 5.54155 8.01981 5.60316 7.99841C5.66476 7.97702 5.73002 7.96815 5.7951 7.97234C5.86018 7.97652 5.92376 7.99368 5.98212 8.02279C6.04047 8.0519 6.09242 8.09238 6.13491 8.14186L7.51998 9.68076L11.2691 5.21849C11.3532 5.12127 11.4721 5.06104 11.6003 5.05083C11.7284 5.04062 11.8554 5.08124 11.9539 5.16393C12.0523 5.24661 12.1142 5.36471 12.1263 5.49269C12.1384 5.62067 12.0996 5.74826 12.0183 5.84788Z" fill="#916BA5" />
            </svg>
            <div className="mini-trade-text">
              Global Payouts
            </div>
          </div>
          <div className="d-flex">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none">
              <rect width="15.6581" height="15.6581" transform="translate(0.878906 0.151367)" fill="white" />
              <path d="M13.1116 1.61865H4.30395C3.78502 1.61922 3.28751 1.82561 2.92058 2.19255C2.55364 2.55948 2.34725 3.05699 2.34668 3.57592V12.3836C2.34725 12.9025 2.55364 13.4001 2.92058 13.767C3.28751 14.1339 3.78502 14.3403 4.30395 14.3409H13.1116C13.6306 14.3403 14.1281 14.1339 14.495 13.767C14.862 13.4001 15.0683 12.9025 15.0689 12.3836V3.57592C15.0683 3.05699 14.862 2.55948 14.495 2.19255C14.1281 1.82561 13.6306 1.61922 13.1116 1.61865ZM12.0183 5.84788L7.90807 10.741C7.86298 10.7947 7.80688 10.8381 7.74356 10.8683C7.68025 10.8984 7.61119 10.9146 7.54108 10.9157H7.53283C7.46424 10.9156 7.39643 10.9012 7.33378 10.8733C7.27114 10.8454 7.21507 10.8046 7.1692 10.7536L5.40766 8.79632C5.36292 8.74887 5.32812 8.69295 5.30531 8.63186C5.28249 8.57077 5.27211 8.50574 5.27478 8.44058C5.27746 8.37542 5.29313 8.31145 5.32088 8.25244C5.34863 8.19342 5.38789 8.14055 5.43637 8.09692C5.48484 8.0533 5.54155 8.01981 5.60316 7.99841C5.66476 7.97702 5.73002 7.96815 5.7951 7.97234C5.86018 7.97652 5.92376 7.99368 5.98212 8.02279C6.04047 8.0519 6.09242 8.09238 6.13491 8.14186L7.51998 9.68076L11.2691 5.21849C11.3532 5.12127 11.4721 5.06104 11.6003 5.05083C11.7284 5.04062 11.8554 5.08124 11.9539 5.16393C12.0523 5.24661 12.1142 5.36471 12.1263 5.49269C12.1384 5.62067 12.0996 5.74826 12.0183 5.84788Z" fill="#916BA5" />
            </svg>
            <div className="mini-trade-text">
              Fast Settlements
            </div>
          </div>
        </div>
        <div className="accept-text">
          <ul>
            <li>
              Accept a wide array of digital currencies,
              including Bitcoin, Ethereum, and more.
            </li>
            <li>
              Expand your customer base by accommodating
              diverse payment preferences.
            </li>
          </ul>
        </div>
        <div className="d-flex arrow-get-started">
          <div className="arrow">
            <img src="https://user-images.githubusercontent.com/36542986/280401623-f68eb932-b321-4eb1-9df4-ff6a80f0bf04.png" alt="arrow" />
          </div>
          <div className="get-started">
            Here’s how to get started
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ThreeSections;

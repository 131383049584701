/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import './Intro.css';

const Intro = () => (
  <div className="intro-container">
    <div className="intro-text">
      <div className="landing-text">
        Experience the Future: Embrace Digital Currency Payments Today
      </div>
      <div className="landing-subtext">
        ExaSync: Empowering Businesses Worldwide to Embrace
        Digital Currency Payments and Serve Customers from
        Across the Globe
      </div>
    </div>
    <div className="form">
      <form>
        <label htmlFor="email">Email address</label>
        <br />
        <input type="email" name="email" placeholder="Enter your email address" />
        <button type="submit">Join Waitlist</button>
      </form>
    </div>
    <div className="auth-buttons">
      <button className="login" type="button">Login</button>
      <button className="sign-up" type="button">Sign up</button>
    </div>
    <div className="coins">
      <div className="trade-text">Trade over 13+ digital currencies with us</div>
      <div className="coins-wrapper">
        <span className="coin-container">
          <div className="coin-logo-usdt min-coins-size" />
          <div>USDT</div>
        </span>
        <div className="coin-container">
          <div className="coin-logo-tether min-coins-size" />
          <div>Tether</div>
        </div>
        <div className="coin-container">
          <div className="coin-logo-busd min-coins-size" />
          <div>BUSD</div>
        </div>
      </div>
    </div>
  </div>
);

export default Intro;

/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import './Waitlist.css';

const Waitlist = () => (
  <div className="waitlist">
    <div className="waitlist-wrapper">
      <header>
        Join Our Waitlist
      </header>
      <div className="waitlist-subtext">
        We're excited to have you on board as we prepare to
        revolutionize the world of digital currency payments.
        By joining our waitlist, you'll be among the first to
        access our cutting-edge services when they become available.
      </div>
      <div className="form">
        <form>
          {/* eslint-disable-next-line */}
            <label htmlFor="email">Email address</label>
          <br />
          <div className="form-elements">
            <input type="email" name="email" placeholder="Enter your email address" />
            <button type="submit">Join Waitlist</button>
          </div>
        </form>
      </div>
    </div>
  </div>
);

export default Waitlist;

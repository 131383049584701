import React from 'react';
import './Empower.css';

const Empower = () => (
  <div className="empower-container">
    <div className="empower-text">
      <div className="empower-bold">
        Empowering Businesses with Digital Currency
        Payment Solutions Across the Globe
      </div>
      <div className="empower-subtext">
        At ExaSync, we specialize in facilitating
        the adoption of digital currency payments for
        businesses worldwide. Our comprehensive suite
        of services includes secure and customizable
        payment processing solutions that enable
        businesses to accept various digital currencies
        seamlessly.
      </div>
    </div>
  </div>
);

export default Empower;

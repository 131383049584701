/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import './FeaturesSection.css';

const Features = () => (
  <div className="features-container">
    <div className="description">
      <header>Features</header>
      <h2>How it works</h2>
      <div>
        Getting started with Exasync is pretty easy
      </div>
    </div>
    <div className="second-section">
      <div className="subsection">
        <div className="image">
          <img src="https://user-images.githubusercontent.com/36542986/281821746-e2e2512f-ed92-4a07-b4cb-23f6f235ebaa.png" alt="phone-charts" />
        </div>
        <header>1. Sign Up</header>
        <div className="subsection-text">
          In the world of business, time is money.
          With ExaSync, you'll experience lightning-fast
          settlement times.
        </div>
        <a href="#">Join Waiting List</a>
      </div>
      <div className="subsection">
        <div className="image">
          <img src="https://user-images.githubusercontent.com/36542986/281830240-cfe4d8cd-75ce-4d10-9d68-8c86d62cf733.jpg" alt="hands-glasses" />
        </div>
        <header>2. Register</header>
        <div className="subsection-text">
          In the world of business, time is money.
          With ExaSync, you'll experience lightning-fast
          settlement times.
        </div>
        <a href="https://google.com">Join Waiting List</a>
      </div>
      <div className="subsection">
        <div className="image">
          <img src="https://user-images.githubusercontent.com/36542986/281830448-64ddc738-abc4-44a9-a3f3-0bd4c422b284.jpg" alt="hand-holding-phone" />
        </div>
        <header>3. Start receiving payments</header>
        <div className="subsection-text">
          In the world of business, time is money.
          With ExaSync, you'll experience lightning-fast
          settlement times.
        </div>
        <a href="#">Join Waiting List</a>
      </div>
    </div>
  </div>
);

export default Features;

/* eslint-disable react/no-unescaped-entities, jsx-a11y/anchor-is-valid */

import React from 'react';
import './Footer.css';
// eslint-disable-next-line
import 'boxicons';

const Footer = () => (
  <div className="footer">
    <div className="footer-wrapper">
      <div className="logo" />
      <div className="four-sections">
        <div className="footer-subsection">
          <header>Quick links</header>
          <a href="#">About Us</a>
          <a href="#">Contact us</a>
          <a href="#">Our Team</a>
          <a href="#">Terms And Privacy</a>
        </div>
        <div className="footer-subsection">
          <header>Contact Info</header>
          <a href="mailto:hello@exasync.com">hello@exasync.com</a>
          <div>+2348994634343</div>
          <a href="#">Our Team</a>
          <a href="#">Terms And Privacy</a>
        </div>
        <div className="footer-subsection">
          <header>Socials</header>
          <div className="socials-links">
            <box-icon type="logo" name="linkedin-square" animation="tada-hover" size="1rem" color="#B296BF" />
            <a href="#">LinkedIn</a>
          </div>
          <div className="socials-links">
            <box-icon type="logo" name="instagram-alt" animation="tada-hover" size="1rem" color="#B296BF" />
            <a href="#">Instagram</a>
          </div>
          <div className="socials-links">
            <box-icon type="logo" name="twitter" animation="tada-hover" size="1rem" color="#B296BF" />
            <a href="#">Twitter</a>
          </div>
        </div>
        <div className="footer-subsection">
          <header>Our Address</header>
          <div className="socials-links">
            <box-icon type="solid" name="map" animation="tada-hover" size="1rem" color="#B296BF" />
            <div className="address-text">
              RGU Startup Station, Level 5, ONE Tech
              <br />
              Hub, Schoolhill, Aberdeen, AB10 1FR
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        © ExaSync 2023. All rights reserved
      </div>
    </div>
  </div>
);

export default Footer;

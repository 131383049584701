/* eslint-disable */

import React from 'react';
import Intro from '../components/Intro/Intro';
import Empower from '../components/Empower/Empower';
import ThreeSections from '../components/ThreeSections/threeSections';
import Features from '../components/FeaturesSection/FeaturesSection';
import Waitlist from '../components/Waitlist/Waitlist';
import Footer from '../components/Footer/Footer';

const Home = () => {
  const fg = 'Ha';
  return (
    <div>
      <Intro />
      <Empower />
      <ThreeSections />
      {/* <WhatWeDo/> */}
      <Features />
      <Waitlist />
      <Footer />
    </div>
  );
};

export default Home;
